.aside-logo[data-v-bb7e7104] {
  margin-bottom: 2px;
  width: 100%;
  height: 60px;
  background-color: #008df7;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.aside-logo img[data-v-bb7e7104] {
    width: 48px;
    height: 48px;
    margin: 0 auto;
    -o-object-fit: contain;
       object-fit: contain;
}
.aside-logo .aside-title[data-v-bb7e7104] {
    width: 100%;
    height: 100%;
    line-height: 60px;
    padding-left: 10px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: inline-block;
    color: #fff;
    font-size: var(--rootFontSize2);
    font-weight: 600;
    text-align: justify;
}
.aside-logo .aside-title[data-v-bb7e7104]:after {
    content: '';
    width: 100%;
    display: inline-block;
}
.collapse-icon[data-v-bb7e7104] {
  font-size: 26px;
  width: 16px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  z-index: 1;
  background: transparent;
  position: fixed;
  top: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  color: var(--mainThemeColor);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: #ffffff;
}
.el-scrollbar[data-v-bb7e7104] {
  border-right: 1px solid #ccc;
}
.el-tree[data-v-bb7e7104] {
  margin-top: 10px;
}
.el-tree[data-v-bb7e7104] .el-tree-node.is-current > .el-tree-node__content {
    background-color: #afd5fd;
}
.el-tree[data-v-bb7e7104] .el-tree-node.is-current > .el-tree-node__content .el-tree-node__label {
      color: #008df7;
}
[data-v-bb7e7104] .el-tree-node {
  margin-bottom: 10px;
}
[data-v-bb7e7104] .el-tree-node .el-tree-node__label {
    font-size: 16px;
}
[data-v-bb7e7104] .el-tree-node .el-tree-node__content {
    letter-spacing: 2px;
    font-weight: 600;
    height: 40px;
    line-height: 40px;
}
